import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { IoMdLogOut } from "react-icons/io";

import Footer from '../Footer/Footer.js';
import config from '../../API/config.js';
import LoginForm from "../LoginForm/LoginForm";
import TodoHome from '../TodoHome/TodoHome.js';
import { changeLoginField, logout, saveLoginSuccessful } from '../../actions/user';

function App() {
	const usernameValue = useSelector((state) => state.user.username);
	const passwordValue = useSelector((state) => state.user.password);
	const logged = useSelector((state) => state.user.logged);
	const dispatch = useDispatch();

	// § Au submit du formulaire
	function handleLogin() {
		axios.post(`${config.apiUrl}/login`, {
			username: usernameValue,
			password: passwordValue
		})
			.then((res) => {
				dispatch(saveLoginSuccessful(res.data.username, res.data.token))
			})
			.catch((err) => {
				alert("Mauvais identifiants")
				console.error(err);
			})
	}

	return (
		<div className="bg-bulle-texture bg-cover bg-center h-screen flex flex-col p-4">
			<h1 className="text-2xl md:text-7xl p-4 pt-0 font-semibold underline mx-auto border-4 rounded-2xl w-fit bg-clear text-dark border-dark">
				Bulle de productivité
			</h1>

			{!logged &&
				<LoginForm
					username={usernameValue}
					password={passwordValue}
					changeField={(newValue, identifier) => {
						dispatch(changeLoginField(newValue, identifier));
					}}
					handleLogin={() => {
						handleLogin()
					}}
				/>
			}

			{logged &&
				<div className='flex-1 my-4 bg-clear bg-opacity-80 w-1/2 mx-auto rounded-xl overflow-hidden'>
					<button className='bg-clear text-dark absolute top-4 right-4 rounded-full p-1' onClick={() => dispatch(logout())}>
						<IoMdLogOut className='text-xl md:text-4xl' />
					</button>
					<TodoHome />
				</div>
			}

			<Footer />
		</div>
	)
}

export default App;