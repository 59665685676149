import { CHANGE_LOGIN_FIELD, LOGOUT, SAVE_LOGIN_SUCCESSFUL } from "../actions/user";

export const initialState = {
	logged: false,
	username: "",
	password: "",
	token: null
};

const reducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case LOGOUT:
			return {
				...state,
				logged: false,
				username: '',
				token: '',
			};

		case SAVE_LOGIN_SUCCESSFUL:
			return {
				...state,
				logged: true,
				username: action.payload.username,
				token: action.payload.token,

				// pour la sécurité je supprime le mot de passe dès que j'en ai plus besoin pour qu'il reste stocké le moins longtemps possible
				password: ''
			};

		case CHANGE_LOGIN_FIELD:
			// deux traitement sur une seule action : soit je change username, soit password
			if (action.payload.identifier === 'username') {
				return {
					...state,
					username: action.payload.newValue,
				};
			}
			// if (action.payload.identifier === 'password')
			return {
				...state,
				password: action.payload.newValue,
			};

		default:
			return state;
	}
};

export default reducer;
