export const CHANGE_LOGIN_FIELD = 'CHANGE_LOGIN_FIELD';
export const SAVE_LOGIN_SUCCESSFUL = 'SAVE_LOGIN_SUCCESSFUL';
export const LOGOUT = 'LOGOUT';

// ¤ Action déclenchée quand un champ est modifié
export const changeLoginField = (newValue, identifier) => ({
	type: CHANGE_LOGIN_FIELD,
	payload: {
		newValue,
		identifier,
	},
});

// ¤ Action déclenchée lors de la connexion aboutie
export const saveLoginSuccessful = (username, token) => ({
	type: SAVE_LOGIN_SUCCESSFUL,
	payload: {
		username,
		token,
	},
});

// ¤ Action déclenchée à la déconnexion
export const logout = () => ({
	type: LOGOUT,
});
