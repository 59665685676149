import PropTypes from 'prop-types';

import { IoMdLogIn } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";

import Field from './Field/Field';

function LoginForm({ username, password, changeField, handleLogin }) {
	// § Au submit du formulaire
	const handleSubmit = (evt) => {
		evt.preventDefault();
		handleLogin();
	};

	return (
		<div className="overflow-hidden flex items-center justify-center h-full">
			<div className="bg-white bg-opacity-70 lg:w-5/12 w-10/12 shadow-3xl rounded-2xl">
				<div className="bg-dark absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-2 md:p-4">
					<IoMdLogIn className="text-4xl md:text-7xl text-clear" />
				</div>
				<form autoComplete="off" className="p-12 md:p-24" onSubmit={handleSubmit}>
					<div className="flex items-center text-lg mb-6 md:mb-8">
						<AiOutlineUser className='ml-3 absolute text-2xl text-dark' />
						<Field
							name="username"
							placeholder="Nom d'utilisateur"
							onChange={changeField}
							value={username}
							className={"bg-clear pl-12 py-2 md:py-4 focus:outline-none w-full"}
						/>
					</div>
					<div className="flex items-center text-lg mb-6 md:mb-8">
						<RiLockPasswordFill className='ml-3 absolute text-2xl text-dark' />
						<Field
							name="password"
							type="password"
							placeholder="Mot de passe"
							onChange={changeField}
							value={password}
							className={"bg-clear pl-12 py-2 md:py-4 focus:outline-none w-full"}
						/>
					</div>
					<button
						type="submit"
						className="bg-gradient-to-b from-[#2B3A02] to-dark font-medium p-2 md:p-4 text-clear uppercase w-full"
					>
						Connexion
					</button>
				</form>
			</div>
		</div>
	);
};

LoginForm.propTypes = {
	/** contenu du champ e-mail */
	username: PropTypes.string.isRequired,
	/** contenu du champ password */
	password: PropTypes.string.isRequired,
	/** Traitement déclenché quand on saisit un caractère dans l'un des deux champs. Paramètres :
	 * - newValue Nouvelle valeur du champ
	 * - identifier Identifiant du champ : 'username' ou 'password'
	*/
	changeField: PropTypes.func.isRequired,
	/** Traitement déclenché quand on clique sur le bouton "OK"
	 * (quand on est en mode non connecté) */
	handleLogin: PropTypes.func.isRequired,
};

export default LoginForm;
