import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MdAddTask } from "react-icons/md";

import config from '../../API/config';
import { addTask } from '../../actions/task';

function TodoForm({ project }) {
	const user = useSelector((state) => state.user.username);
	const token = useSelector((state) => state.user.token);
	const [error, setError] = useState(null);
	const [task, setTask] = useState('');
	const dispatch = useDispatch();

	// § Fonction qui ajoute la nouvelle tâche dans la base de données
	function handleAddTask(e) {
		e.preventDefault();
		if (task && task !== '' && task.length <= 255) {
			axios.post(`${config.apiUrl}/tasks/new`,
				{
					task: task,
					user: user,
					project: project
				},
				// options, notamment les headers
				// => transmissions du token jwt au serveur, pour qu'il nous reconnaisse
				{
					headers:
					{
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					// j'ajoute la tâche à la liste des tâches du projet
					dispatch(addTask(res.data));
					// je remets les champs à zéro
					setTask('');
					setError(null);
				})
				.catch((err) => {
					// message en cas d'échouage de la requête
					setError('une erreur est survenue');
					console.error(err);
				})
		} else if (task.length > 255) {
			setError('Votre tâche est trop longue (maxi 255 caractères)')
		}
	}

	return (
		<form
			className='mx-5'
			onSubmit={(e) => { handleAddTask(e) }}
		>
			{error && <p className="text-red-600">{error}</p>}
			<div className="flex items-center text-lg mb-1">
				<MdAddTask className='ml-3 absolute text-2xl text-dark' />
				<input
					type='text'
					className="bg-clear px-12 py-2 focus:outline-none w-full rounded border-2 border-dark"
					placeholder='Ajouter une tâche'
					value={task}
					onChange={(event) => {
						setTask(event.target.value);
					}}
				/>
			</div>
		</form>
	);
}

export default TodoForm;
