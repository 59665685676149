import axios from "axios";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { IoTrashBin } from "react-icons/io5";

import config from '../../API/config';
import { deleteProject } from "../../actions/projects";

function ModalDeleteProject({ projectId, setSelectedProject }) {
    const token = useSelector((state) => state.user.token);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();

    // § Au submit du formulaire
    function handleSubmit(e) {
        e.preventDefault();
        axios.post(`${config.apiUrl}/projects/delete`, {
            idProject: projectId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // j'ajoute le projet à la liste
                dispatch(deleteProject(parseInt(projectId)));
                setSelectedProject('');
                // je remets les champs à zéro
                // je ferme la modale
                setOpenModal(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <button aria-label="Ajouter un projet" onClick={() => setOpenModal(true)}>
                <IoTrashBin className="text-2xl text-red-700" />
            </button>

            {openModal &&
                <div className="absolute top-0 left-0 w-full bg-dark bg-opacity-90 h-full z-10">
                    <form className="flex flex-col m-auto w-fit items-center h-full justify-center" onSubmit={(e) => handleSubmit(e)}>
                        <h3 className="bg-dark w-full text-clear flex justify-between p-2 rounded-t-lg">
                            Ajouter un nouveau projet
                            <button className="bg-clear text-dark px-1 hover:bg-red-600" type="button" onClick={() => setOpenModal(false)}><IoCloseSharp /></button>
                        </h3>
                        <div className="bg-white flex flex-col w-full p-4 gap-2 items-center rounded-b-lg shadow-xl">
                            <strong>Êtes-vous certain de vouloir supprimer ce projet ?</strong>
                            <i>Ceci supprimera également toutes les tâches qui lui sont associées.</i>

                            <div className="space-x-2">
                                <button
                                    type="submit"
                                    className="bg-gradient-to-b from-[#2B3A02] to-dark font-medium p-2 text-clear uppercase w-fit text-sm"
                                >
                                    Oui
                                </button>
                                <button
                                    type="button"
                                    className="bg-gradient-to-b from-[#2B3A02] to-dark font-medium p-2 text-clear uppercase w-fit text-sm"
                                    onClick={() => setOpenModal(false)}
                                >
                                    Non
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </>
    );
}

export default ModalDeleteProject;