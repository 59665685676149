import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../API/config';
import TodoList from '../TodoList/TodoList';
import TodoForm from '../TodoForm/TodoForm';
import { setTasks } from '../../actions/task';
import { setProjects } from '../../actions/projects';
import ModalAddProject from '../ModalAddProject/ModalAddProject';
import ModalDeleteProject from '../ModalDeleteProject/ModalDeleteProject';

function TodoHome() {
    const username = useSelector((state) => state.user.username);
    const projects = useSelector((state) => state.projects.list);
    const tokenValue = useSelector((state) => state.user.token);
    const [selectedProject, setSelectedProject] = useState('');
    const dispatch = useDispatch();

    // je récupère la liste des projets associés à l'utilisateur connecté
    useEffect(() => {
        axios.post(`${config.apiUrl}/projects/all`,
            {
                username: username
            },
            // options, notamment les headers
            // => transmissions du token jwt au serveur, pour qu'il nous reconnaisse
            {
                headers:
                {
                    Authorization: `Bearer ${tokenValue}`,
                },
            })
            .then((res) => {
                dispatch(setProjects(res.data));
            })
            .catch((err) => { console.error(err) })
    }, [dispatch, tokenValue, username])

    // lorsque je change de projet, je vais récupérer les tâches associées au projet sélectionné
    function handleChangeProject(project) {
        axios.post(`${config.apiUrl}/tasks/allByProject`,
            {
                project: project
            },
            // options, notamment les headers
            // => transmissions du token jwt au serveur, pour qu'il nous reconnaisse
            {
                headers:
                {
                    Authorization: `Bearer ${tokenValue}`,
                },
            })
            .then((res) => {
                dispatch(setTasks(res.data));
            })
            .catch((err) => { console.error(err) })
    }

    return (
        <>
            <div className='flex gap-2 justify-center m-2'>
                <select
                    value={selectedProject}
                    onChange={(e) => {
                        setSelectedProject(e.target.value);
                        handleChangeProject(e.target.value);
                    }}
                    className='bg-clear rounded-lg py-1 px-2 border-2 border-dark'
                >
                    <option value=''>Sélectionner un projet</option>
                    {projects.map((project) => (
                        <option key={project.id} value={project.id}>{project.name}</option>
                    ))}
                </select>
                {!selectedProject && <ModalAddProject />}
                {selectedProject && 
                    <ModalDeleteProject projectId={selectedProject} setSelectedProject={setSelectedProject} />
                }
            </div>

            <div className='h-full'>
                {!selectedProject &&
                    <strong className='m-auto w-fit block'>Sélectionner un projet pour afficher la liste de tâches.</strong>
                }

                {selectedProject &&
                    <>
                        {projects
                            .filter((project) => (project.id === parseInt(selectedProject)))
                            .map((project) => (
                                <div key={project.id} className='text-center mb-2 w-fit mx-auto px-5 max-w-3/4'>
                                    {project.description}
                                </div>
                            ))
                        }

                        <hr className="w-96 h-1 mx-auto my-2 bg-dark border-0 rounded" />

                        <TodoForm project={selectedProject} />
                        <TodoList />
                    </>
                }
            </div>
        </>
    );
}

export default TodoHome;