import PropTypes from 'prop-types';

function Field({ value = "", type = "text", name, placeholder, onChange, className }) {
	// § Au changement du champ
	const handleChange = (evt) => {
		onChange(evt.target.value, name);
	};

	const inputId = `field-${name}`;

	return (
		<div className={`w-full ${value.length > 0 ? 'field field--has-content' : 'field'}`}>
			<input
				// React - state
				value={value}
				onChange={handleChange}
				// infos de base
				id={inputId}
				type={type}
				className={className}
				placeholder={placeholder}
				name={name}
			/>
		</div>
	);
};

Field.propTypes = {
	value: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default Field;
