// ici c'est le "chef des reducers", celui qui affecte un tiroir à un reducer
// et qui leur donne le nom
import { combineReducers } from 'redux';

// on importe les reducers
import userReducer from './user';
import projectsReducer from './projects';
import tasksReducer from './tasks';

// lien entre les tiroirs et les reducers
const rootReducer = combineReducers({
  // nom du tiroir : nom du reducer qui s'en occupe
  user: userReducer,
  projects: projectsReducer,
  tasks: tasksReducer
});

export default rootReducer;
