import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '../reducers/index';

// middleware : u noutil de Redux pour gérer les appels API
// ? pas vu pendant la spé, on a fait différement


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(
  applyMiddleware(),
);

const store = createStore(reducer, enhancers);

export default store;
