export const ADD_TASK = 'ADD_TASK';
export const SET_TASKS = 'SET_TASKS';
export const CLOSE_TASK = 'CLOSE_TASK';

// ¤ Action déclenchée quand on envoie une tâche en BDD
export const addTask = (task) => ({
    type: ADD_TASK,
    payload: {
        task,
    },
});

// ¤ Action déclenchée lors de la récupération des tâches
export const setTasks = (data) => ({
    type: SET_TASKS,
    payload: {
        tasks: data,
    },
});

// ¤ Action déclenchée lors de la fermeture d'une tâche
export const closeTask = (task) => ({
    type: CLOSE_TASK,
    payload: {
        task,
    },
});
