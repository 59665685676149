import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import store from './store/store';
import App from './components/App/App';

import './styles/index.css'

const rootReactElement = (
    <Provider store={store}>
        <App />
    </Provider>
);

const root = createRoot(document.getElementById('root'));
root.render(rootReactElement);