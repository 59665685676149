import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../API/config';
import { closeTask } from '../../actions/task';

function TodoList() {
    const tasks = useSelector((state) => state.tasks.list);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();

    // § Fonction lors de la cloture d'une tâche
    function toggleTask(task) {
        axios.post(`${config.apiUrl}/tasks/closed`,
            {
                taskId: task
            },
            // options, notamment les headers
            // => transmissions du token jwt au serveur, pour qu'il nous reconnaisse
            {
                headers:
                {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(closeTask(task));
            })
            .catch((err) => { console.error(err) })
    }

    return (
        tasks.length > 0 ?
            <ul className='flex flex-col gap-1 h-[calc(100%-155px)] overflow-y-auto'>
                {tasks
                    .slice()
                    .sort((a, b) => a.closed - b.closed)
                    .map((task) => (
                        <li
                            key={task.id}
                            className={`flex gap-2 p-2 mx-5 border-2 border-dark rounded bg-clear ${task.closed ? "opacity-50 line-through" : ""}`}
                        >
                            <input
                                type='checkbox'
                                checked={task.closed}
                                onChange={() => {
                                    toggleTask(task.id);
                                }}
                            />
                            <p>{task.task}</p>
                        </li>
                    ))}
            </ul>
            :
            <strong className='ml-5'>Il n'y a pas de tâche pour ce projet</strong>
    );
}

export default TodoList;