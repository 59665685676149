import axios from "axios";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { RiAddFill } from "react-icons/ri";

import config from '../../API/config';
import { addProject } from "../../actions/projects";

function ModalAddProject() {
    const [projectDescription, setProjectDescription] = useState('');
    const username = useSelector((state) => state.user.username);
    const token = useSelector((state) => state.user.token);
    const [projectName, setProjectName] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    function handleAddProject(e) {
        e.preventDefault();
        // si mes champs soint bien remplis et qu'ils ne dépassent le nombre de caractères autorisés
        // ? 50 pour le nom et 255 pour la description
        if (projectName && projectDescription && projectName.length <= 50 && projectDescription.length <=255) {
            axios.post(`${config.apiUrl}/projects/new`, {
                name: projectName,
                description: projectDescription,
                user: username,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // j'ajoute le projet à la liste
                    dispatch(addProject(res.data));
                    // je remets les champs à zéro
                    setProjectName('');
                    setProjectDescription('');
                    setError(null);
                    // je ferme la modale
                    setOpenModal(false);
                })
                .catch((err) => {
                    // message en cas d'échouage de la requête
                    setError('une erreur est survenue');
                    console.error(err);
                });
        } else if (projectName.length > 50) {
            // message d'erreur pour un nom de projet trop long
            setError('Le nom est trop long (50 maxi)')
        } else if (projectDescription.length > 250) {
            // message d'erreur pour une description trop longue
            setError('La description est trop longue (255 maxi)')
        }
    }

    return (
        <>
            <button aria-label="Ajouter un projet" onClick={() => setOpenModal(true)}>
                <RiAddFill className="text-2xl bg-clear rounded-lg border-2 border-dark" />
            </button>

            {openModal &&
                <div className="absolute top-0 left-0 w-full bg-dark bg-opacity-90 h-full z-10">
                    <form className="flex flex-col m-auto w-fit items-center h-full justify-center" onSubmit={(e) => handleAddProject(e)}>
                        <h3 className="bg-dark w-full text-clear flex justify-between p-2 rounded-t-lg">
                            Ajouter un nouveau projet
                            <button className="bg-clear text-dark px-1 hover:bg-red-600" type="button" onClick={() => setOpenModal(false)}><IoCloseSharp /></button>
                        </h3>
                        <div className="bg-white flex flex-col w-full p-4 gap-2 items-center rounded-b-lg shadow-xl">
                            {error && <p className="text-red-600">{error}</p>}
                            <input
                                type="text"
                                className="px-2 py-1 m-2 w-full bg-clear border-2 border-dark rounded-lg"
                                placeholder="Nom du projet"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                required
                            />

                            <textarea
                                className="px-2 py-1 w-80 bg-clear border-2 border-dark rounded-lg"
                                placeholder="Description"
                                value={projectDescription}
                                onChange={(e) => setProjectDescription(e.target.value)}
                                required
                            >
                            </textarea>

                            <button type="submit" className="bg-gradient-to-b from-[#2B3A02] to-dark font-medium p-2 text-clear uppercase w-fit text-sm">Ajouter</button>
                        </div>
                    </form>
                </div>
            }
        </>
    );
}

export default ModalAddProject;