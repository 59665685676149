import { ADD_PROJECT, DELETE_PROJECT, SET_PROJECTS } from '../actions/projects';

// => "list" est accessible avec state.projects.list, pas state.list
export const initialState = {
  list: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        list: action.payload.projects,
      };

    case ADD_PROJECT:
      return {
        ...state,
        list: [...state.list, action.payload.project],
      };

    case DELETE_PROJECT:
      const updatedList = state.list.filter(project => project.id !== action.payload.project);
      return {
        ...state,
        list: updatedList,
      };
    default:
      return state;
  }
};


export default reducer;
