import { ADD_TASK, CLOSE_TASK, SET_TASKS } from "../actions/task";

// => "list" est accessible avec state.tasks.list, pas state.list
export const initialState = {
	list: [],
};

const reducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case ADD_TASK:
			return {
				...state,
				list: [...state.list, action.payload.task],
			};

		case SET_TASKS:
			return {
				...state,
				list: action.payload.tasks,
			};

		case CLOSE_TASK:
			const taskIdToClose = action.payload.task;
			return {
				...state,
				list: state.list.map(task =>
					task.id === taskIdToClose ? { ...task, closed: true } : task
				),
			};

		default:
			return state;
	}
};

export default reducer;