import { FaRegCopyright } from "react-icons/fa6";

function Footer() {
    return (
        <div className="flex items-center gap-2 bg-clear text-dark w-fit m-auto p-1 rounded-3xl bg-opacity-50">
            <FaRegCopyright />
            Création : Jordan DevWeb
        </div>
    );
}

export default Footer;