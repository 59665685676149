export const SET_PROJECTS = 'SET_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

// ¤ Action déclenchée pour récupérer tous les projets d'un utilisateur
export const setProjects = (data) => ({
    type: SET_PROJECTS,
    payload: {
        projects: data,
    },
});

// ¤ Action déclenchée lors de l'ajout d'un nouveau projet
export const addProject = (project) => ({
    type: ADD_PROJECT,
    payload: {
        project,
    },
});

// ¤ Action déclenchée lors de la suppression d'un projet
export const deleteProject = (project) => ({
    type: DELETE_PROJECT,
    payload: {
        project,
    },
});